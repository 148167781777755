@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.3.0/font/bootstrap-icons.css");

*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

section {
    max-width: 1280px;
    margin: auto;
    height: 100%;
    padding: 10px;
}

.active {
    color: blue;
    padding: 10px;
    border-radius: 5px;
}



@media not all and (min-width: 640px){
    .active{
        background-color: hsl(240, 81%, 61%);
        padding: 5px;
        border-radius: 5px;
        color: #fff;
    }
}